'use client';

import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useRouter } from 'next/navigation';

import { Eye, EyeSlash, Timer } from '@phosphor-icons/react';

import useApi, { Methods } from '@/global/hooks/useApi';
import useWindowSize from '@/global/hooks/useWindowSize';
import LoadingIcon from '@/global/icons/LoadingIcon';
import LogoIcon from '@/global/icons/LogoIcon';
import SprintIcon from '@/global/icons/SprintIcon';
import StatistiquesIcon from '@/global/icons/StatistiquesIcon';
import { useToastStore } from '@/global/zustand/toastStore';
import { useUserStore } from '@/global/zustand/userStore';

import styles from './LoginComponent.module.scss';

const LoginComponent = () => {
   const { response, error, loading, callApi } = useApi();
   const { handleSubmit, register } = useForm();
   const { setUser } = useUserStore();
   const { setHandleStatusToast } = useToastStore();

   const router = useRouter();
   const { width } = useWindowSize();
   const [passwordShown, setPasswordShown] = useState(false);

   const onSubmit = (data: any) => {
      callApi(Methods.POST, '/api/auth/login', data);
   };

   useEffect(() => {
      if (response) {
         setUser(response.res.user);
         const findUser = response.res.user?.team?.TeamsUsersLink.find(
            (link: any) => link.user.id === response.res.user.id
         );

         setHandleStatusToast({
            status: true,
            title: 'Connexion réussie',
            response: 'success',
         });

         if (findUser && findUser.role_name === 'invite') {
            router.push(`${findUser.user.team.slug}/projets`);
         } else if (
            (findUser && findUser.role_name === 'admin') ||
            (findUser && findUser.role_name === 'member')
         ) {
            router.push(`${findUser.user.team.slug}/tableau`);
         } else {
            router.push(`${findUser.user.team.slug}/profil`);
         }
      }
   }, [response]);

   useEffect(() => {
      if (error) {
         setHandleStatusToast({
            status: true,
            title: `Echec: mot de passe ou adresse email incorrecte.`,
            response: 'error',
         });
      }
   }, [error]);

   const togglePassword = () => {
      setPasswordShown(!passwordShown);
   };

   return (
      <div className={styles.container}>
         <div className={styles.wrapper}>
            <div className={styles.logo}>
               <LogoIcon />
            </div>
            <div className={styles.wrapperForm}>
               <div className={styles.left}>
                  <h1 className={styles.title}>Connexion</h1>
                  <form onSubmit={handleSubmit(onSubmit)}>
                     <div className="m-input">
                        <label htmlFor="email">
                           Adresse email <span>*</span>
                        </label>
                        <div className="m-input__core">
                           <input
                              data-cy="email"
                              type="email"
                              placeholder="Adresse email"
                              {...register('email', { required: true })}
                           />
                        </div>
                     </div>
                     <div className="m-input">
                        <label htmlFor="email">
                           Mot de passe <span>*</span>
                        </label>
                        <div className={'m-input__core'}>
                           <input
                              data-cy="password"
                              type={passwordShown ? 'text' : 'password'}
                              placeholder="Mot de passe"
                              {...register('password', { required: true })}
                           />
                           <button
                              type="button"
                              onClick={togglePassword}
                              className="m-input__core__suffix"
                           >
                              {!passwordShown ? (
                                 <Eye size={20} />
                              ) : (
                                 <EyeSlash size={20} />
                              )}
                           </button>
                        </div>
                     </div>
                     <div className="m-checkbox m-checkbox--label">
                        <input type="checkbox" />
                        <span></span>
                        <label>Se souvenir de moi</label>
                     </div>
                     <div className={styles.btnLogin}>
                        <button
                           data-cy="submit-login"
                           type="submit"
                           className="m-button m-button--primary"
                        >
                           {loading ? (
                              <span>
                                 <LoadingIcon />
                              </span>
                           ) : (
                              <span>Connexion</span>
                           )}
                        </button>
                        <div className={styles.noaccount}>
                           <span className={styles.text}>
                              Pas encore de compte ?
                           </span>
                           <button
                              onClick={() => router.push('/inscription')}
                              type="button"
                              className="m-button m-button--secondary"
                           >
                              <span>Créer un compte</span>
                           </button>
                        </div>
                     </div>
                  </form>
               </div>
               <div className={styles.right}>
                  <div className={styles.card}>
                     <div className={styles.icon}>
                        <SprintIcon />
                     </div>
                     <div className={styles.content}>
                        <h2 className={styles.subtitle}>
                           Gérez votre production
                        </h2>
                        <p className={styles.cardText}>
                           Créez vos clients, vos projets et gérez un backlog de
                           stories et des sprints de travail très simplement
                           sous forme de kanban ou de planing macro
                        </p>
                     </div>
                  </div>
                  <div className={styles.card}>
                     <div className={styles.icon}>
                        <Timer weight="fill" />
                     </div>
                     <div className={styles.content}>
                        <h2 className={styles.subtitle}>
                           Time tracking simple et efficace
                        </h2>
                        <p className={styles.cardText}>
                           Suivez votre activité en temps réel sur chaque projet
                           et générez des reportings détaillés pour vos clients
                           en 1 clic
                        </p>
                     </div>
                  </div>
                  <div className={styles.card}>
                     <div className={styles.icon}>
                        <StatistiquesIcon />
                     </div>
                     <div className={styles.content}>
                        <h2 className={styles.subtitle}>
                           Statistiques détaillées
                        </h2>
                        <p className={styles.cardText}>
                           Visualisez les temps de travail de chaque membre de
                           votre équipe sur vos différents projets
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default LoginComponent;
