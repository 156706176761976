'use client';

import Image from 'next/image';
import Link from 'next/link';

import styles from './not-found.module.scss';

export default function NotFound() {
   return (
      <div className={styles.notFoundContainer}>
         <div className={styles.content}>
            <Image src="/images/lost.gif" alt="404" width={400} height={400} />
            <h2>Oups ! On dirait que tu t'es perdus...</h2>
            <p>Pas de panique, il te suffis de revenir en arrière !</p>
            <Link href="/" className={'m-button m-button--primary'}>
               <span>Retourner à l'accueil</span>
            </Link>
         </div>
      </div>
   );
}
