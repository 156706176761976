import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import useApi, { Methods } from '@/global/hooks/useApi';

interface UserState {
   user: any | null;
   role: string;
   filterPlanning: any;
   setUser: (data: any) => void;
   clearUser: () => void;
   setFilterPlanning: (filterPlanning: any) => void;
}

const initialState = {
   user: null,
   role: '',
   filterPlanning: null,
};

export const useUserStore = create<UserState>()(
   persist(
      (set, get) => ({
         ...initialState,

         setUser: (data) => {
            set({ user: data });

            if (data && data.team?.TeamsUsersLink) {
               const findUserInTeam = data.team.TeamsUsersLink.find(
                  (element: any) => element.user.id === data.id
               );

               if (findUserInTeam) {
                  set({ role: findUserInTeam.role_name || '' });

                  if (findUserInTeam.filterPlanning) {
                     set({
                        filterPlanning: JSON.parse(
                           findUserInTeam.filterPlanning
                        ),
                     });
                  }
               }
            }
         },

         setFilterPlanning: async (newFilterPlanning) => {
            set({ filterPlanning: newFilterPlanning });

            try {
               const { callApi } = useApi();
               await callApi(Methods.PUT, '/api/planning', newFilterPlanning);
            } catch (error) {
               console.error('Failed to update planning:', error);
            }
         },

         clearUser: () => set(initialState),
      }),
      {
         name: 'user-storage',
         storage: createJSONStorage(() => localStorage),
         partialize: (state) => ({
            user: state.user,
            role: state.role,
            filterPlanning: state.filterPlanning,
         }),
         version: 1,
         onRehydrateStorage: (state) => {
            console.log('hydration finished');
            return (state, error) => {
               if (error) {
                  console.log('an error happened during hydration', error);
               }
            };
         },
      }
   )
);

if (typeof window !== 'undefined') {
   window.addEventListener('storage', (e) => {
      if (e.key === 'user-storage') {
         window.location.reload();
      }
   });
}
